const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://www.victorpedros.com',
  title: 'Víctor Pedrós.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Víctor Pedrós',
  role: 'Manager, Scrum Master and Product Owner',
  description:
    'My name is Víctor Pedrós Orozco, I am a Graduate in Computer Engineering and Cybersecurity. Able to develop and carry out the processes that involve a life cycle in a computer engineering project from the requirements analysis step to the final delivery of the product. I bring more than tree years experience on management and i consider myself a hardworking, dynamic, responsible person, with easy adaptation and teamwork. I have an open mind and initiative to face new challenges and difficulties',
  resume: 'https://www.victorpedros.com/cv.pdf',
  social: {
    linkedin: 'https://www.linkedin.com/in/victor-pedros/'
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Project Manager',
    company: 'ORIZON',
    description:
      'Management of cost and performance optimization projects',
    stack: ['Project Manager', 'Manager', 'Jira', 'Confluence'],
    year: 'FEB. 2023 - Actually',
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },{
    name: 'Manager',
    company: 'FACEPHI BIOMETRIA, S.A',
    description:
      'Management of different projects together with the client integration phases. Coordination of various teams. Design and planning of work in transversal areas. DevOps, QA, talent management, security, etc',
    stack: ['Scrum Master', 'Product Owner', 'Manager', 'Jira', 'Confluence', 'Airtable'],
    year: 'Jan. 2021 - Nov. 2022',
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'PMO Developer Technician',
    company: 'Goldcar S.L.',
    description:
      'Backend developer developing an ERP with delphi technology. Applying scrum and TDD methodology',
    stack: ['Delphi', 'SQL', 'Java', 'Junit', 'Scrum', 'Jira', 'Notion'],
    year: 'Mar. 2018 - Jan. 2021',
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: '.NET Developer',
    company: 'NTT Data',
    description:
      'Developer of applications in .NET language for banking, working on different projects like Banco Santander, BBVA, Redsys or Correos. Also i have worked on SSIS systems to develop ETL process.',
    stack: ['C#', 'SQL', 'SSIS', 'CSS', 'Javascript', 'TFM', 'Git'],
    year: 'Mar. 2015 - Jul. 2017',
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: '.NET Developer',
    company: 'DYA Alicante',
    description:
      'Developer of a application in .NET language with Razor framework to manage company process.',
    stack: ['C#', 'SQL', 'Razor', 'Entity Framework', 'Javascript', 'CSS', 'Git'],
    year: 'dic. 2013 - dic. 2014',
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'PHP Developer',
    company: 'Proyecto Español',
    description:
      'Web application development with PHP technology with the laravel framework.',
    stack: ['PHP', 'SQL', 'Laravel', 'Javascript','html', 'CSS', 'Git'],
    year: 'jun. 2013 - dic. 2013',
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Frontend Developer',
    company: 'Dos Estudio',
    description:
      'Developing web applications with different technologies',
    stack: ['PHP', 'SQL','Javascript','html', 'CSS', 'JQuert'],
    year: 'jul. 2012 - may. 2013',
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  }
]

const educations = [
  // educations can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Project Manager By google',
    description:
      'Coursera',
    year: '2023',
    stack: ['online']
  },
  {
    name: 'Master in Cybersecurity',
    description:
      'University of Alicante',
    year: '2019-2020',
    stack: ['Alicante', 'Spain']
  },
  {
    name: 'Bachellor degree Computer Science',
    description:
      'University of Alicante',
    year: '2011-2015',
    stack: ['Alicante', 'Spain']
  },
  {
    name: 'Expert on Network and servers',
    description:
      'Catholic University of Ávila',
    year: '2014-2015',
    stack: ['Online', 'Spain']
  },
  {
    name: 'Linux: networks administration and servers',
    description:
      'Catholic University of Ávila',
    year: '2012-2012',
    stack: ['Online', 'Spain']
  },
  {
    name: 'English B2 Cambridge',
    description:
      'EF International Language Centers',
    year: '2010-2011',
    stack: ['Auckland - New Zealand', 'Dublin - Ireland']
  },
  {
    name: 'Technician in computer systems administration',
    description:
      'IES Mare Nostrum',
    year: '2008-2010',
    stack: ['Alicante', 'Spain']
  }
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'SCRUM',
  'Kanban',
  'Agile',
  'Flow process',
  'Management',
  'Teamwork',
  'Product Owner'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'info@victorpedros.com',
}

export { header, about, projects, educations, skills, contact }
